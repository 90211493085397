import { KNOWN_LOCALES, KnownLocale } from 'logic/translations/i18n-config';

export const isRtl = (language: string): boolean => language === 'ar-AE';

export const getLanguageFromStorage = (): string | null =>
  window?.sessionStorage.getItem('preferred_language');

export const storeLanguage = (language: string): void => {
  window?.sessionStorage.setItem('preferred_language', language);
};

export const findKnownLocale = (lang?: string | null): KnownLocale | undefined =>
  !lang ? undefined : KNOWN_LOCALES.find((kl) => lang?.startsWith(kl));

export const getBrowserLanguage = () => navigator?.languages?.[0] || navigator?.language;

export const getLocaleFromStorageOrBrowser = () => {
  const storageLanguage = getLanguageFromStorage();
  const browserLanguage = getBrowserLanguage();

  return findKnownLocale(storageLanguage) || findKnownLocale(browserLanguage);
};
