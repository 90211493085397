// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-hilfe-tsx": () => import("./../../../src/pages/hilfe.tsx" /* webpackChunkName: "component---src-pages-hilfe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-nachhilfe-geben-tsx": () => import("./../../../src/pages/nachhilfe-geben.tsx" /* webpackChunkName: "component---src-pages-nachhilfe-geben-tsx" */),
  "component---src-pages-nachhilfeschulen-tsx": () => import("./../../../src/pages/nachhilfeschulen.tsx" /* webpackChunkName: "component---src-pages-nachhilfeschulen-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */)
}

