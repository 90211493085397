// import "styles/fonts.css"
// import "styles/animations.css"
import wrapPage from './src/wrapPageElement';

const addScript = (url, callback) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false;
  if (callback) {
    if (script.readyState) {
      // only required for IE <9
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = function () {
        callback();
      };
    }
  }
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  if (process.env.GATSBY_DISABLE_COOKIE_CONSENT === 'true') return;
  addScript('https://cmp.uniconsent.com/v2/5d3b7b0b3e/cmp.js');
};

export const wrapPageElement = wrapPage;
