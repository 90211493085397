module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TutorSpace","short_name":"TutorSpace","start_url":"/","background_color":"#FFE81D","theme_color":"#FFE81D","display":"minimal-ui","icon":"static/icons/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f42e09f942d3aa95c5f7c2025c97f07b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PKCM996X","includeInDevelopment":false,"enableWebVitalsTracking":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"AW-803855886","autoStart":true,"anonymize":false,"controlCookieName":"gatsby-gdpr","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"1847904471912193","controlCookieName":"gatsby-gdpr"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://deb3f1117893448bacd858dc988c3fea@o459358.ingest.sentry.io/5466429"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
