import React from 'react';
import LanguageContext from './logic/context/language';
import { isRtl } from './logic/i18n/i18n.logic';
import { setHistory } from './app/app.route.fix';
import { LynxHelmet } from './components/helmets/LynxHelmet';

const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { language: currentLanguage, languages },
    location,
  } = props;
  setHistory(location);
  return (
    <>
      <LynxHelmet />
      <LanguageContext.Provider
        value={{ currentLanguage, languages, isRtl: isRtl(currentLanguage) }}
      >
        {element}
      </LanguageContext.Provider>
    </>
  );
};

export default wrapPageElement;
