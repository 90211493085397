export const isProduction = () => process.env.NODE_ENV === 'production';
export const isStaging = () => process.env.NODE_ENV === 'production';
export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isTest = () => process.env.NODE_ENV === 'test';
export const isContentfulDevelop = () =>
  process.env.GATSBY_CONTENTFUL_ENVIROMENTS === ContentfulEnvs.develop;

enum ContentfulEnvs {
  develop = 'develop',
  master = 'master',
}

const getEnv = () => ({
  NODE_ENV: process.env.NODE_ENV,
  GATSBY_REACT_APP_API_URL: process.env.GATSBY_REACT_APP_API_URL,
  GATSBY_REACT_APP_FB_APP_ID: process.env.GATSBY_REACT_APP_FB_APP_ID,
  GATSBY_REACT_APP_APPLE_CLIENT_ID: process.env.GATSBY_REACT_APP_APPLE_CLIENT_ID,
  GATSBY_REACT_APP_MAP_BOX_TOKEN: process.env.GATSBY_REACT_APP_MAP_BOX_TOKEN,
  GATSBY_REACT_APP_SENTRY_DSN: process.env.GATSBY_REACT_APP_SENTRY_DSN,
  GATSBY_STRIPE_PK_PROD: process.env.GATSBY_STRIPE_PK_PROD || '',
  GATSBY_STRIPE_PK_DEV: process.env.GATSBY_STRIPE_PK_DEV || '',
  ...getDevEnv(),
});

const getDevEnv = () =>
  (!isProduction() && {
    GATSBY_REACT_APP_DEV_PHONE_COUNTRY_CODE: process.env.GATSBY_REACT_APP_DEV_PHONE_COUNTRY_CODE,
    GATSBY_REACT_APP_DEV_PHONE: process.env.GATSBY_REACT_APP_DEV_PHONE,
    GATSBY_REACT_APP_DEV_EMAIL: process.env.GATSBY_REACT_APP_DEV_EMAIL,
    GATSBY_REACT_APP_DEV_PASSWORD: process.env.GATSBY_REACT_APP_DEV_PASSWORD,
    GATSBY_REACT_APP_DEV_ZIP_CODE: process.env.GATSBY_REACT_APP_DEV_ZIP_CODE,
    GATSBY_REACT_APP_DEV_FIRST_NAME: process.env.GATSBY_REACT_APP_DEV_FIRST_NAME,
    GATSBY_REACT_APP_DEV_LAST_NAME: process.env.GATSBY_REACT_APP_DEV_LAST_NAME,
  }) ||
  {};

export const env = getEnv();
