import { createContext } from 'react';

export type Languages = 'en-US' | 'de';

interface LanguageContextType {
  currentLanguage: Languages;
  languages: Array<Languages>;
  isRtl: boolean;
}

const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: 'de',
  languages: ['en-US', 'de'],
  isRtl: false,
});

export default LanguageContext;
