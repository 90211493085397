import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { isDevelopment } from 'logic/env/envs';

export const LynxHelmet: FC = () => {
  if (isDevelopment()) {
    return <></>;
  }

  return (
    <Helmet>
      <script>
        {`(function(w,d,s,l){w[l]=w[l]||[];w[l].push({'dd360.start':
new Date().getTime(),event:'dd360.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://lynx.tutorspace.de/lynx/client?id='+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer');`}
      </script>
    </Helmet>
  );
};
