// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum _KnownLocale {
  DE = 'de',
  EN = 'en',
  UA = 'ua',
}

export type KnownLocale = `${_KnownLocale}`;
export const KNOWN_LOCALES = Object.values(_KnownLocale) as KnownLocale[];

export interface I18nConfig {
  readonly locale: KnownLocale;
}

export const defaultI18nConfig: I18nConfig = {
  locale: 'de',
};

export interface I18nConfigCallbacks {
  setLocale: (locale: KnownLocale) => void;
}

export const noopI18nConfigCallbacks: I18nConfigCallbacks = {
  setLocale: () => {},
};
